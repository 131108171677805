/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 15:46:23
 * @LastEditors: Arvin
 * @LastEditTime: 2021-01-14 10:09:06
 */
import { createNamespacedHelpers, mapActions, mapGetters as mapRootGetters } from 'vuex'
import IconTitle from '../common/iconTitle'
import NProgress from '../common/nProgress'
import RoundPieChart from '../common/roundPieChart'

const { mapGetters } = createNamespacedHelpers('Operate/Overview')

export default {
  name: 'TotalData',
  components: {
    IconTitle,
    NProgress,
    RoundPieChart
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'overviewData',
      'totalData'
    ]),

    ...mapRootGetters([
      'accountInfo',
      'can_buy_combo'
    ]),

    // 用户量
    totalDeviceid () {
      return this.totalData.total_deviceid
    },

    // ios用户百分比
    iosDeviceidPercent () {
      if (!this.totalDeviceid) {
        return '0.00'
      }
      const {
        ios_total_deviceid: iosDeviceid
      } = this.totalData
      return (iosDeviceid * 100 / this.totalDeviceid).toFixed(2)
    },

    iosChartData () {
      const value = this.iosDeviceidPercent - 0
      return {
        value,
        color: 'rgba(201, 67, 243, 1)',
        endColor: 'rgba(255, 114, 206, 1)'
      }
    },

    // 安卓用户百分比
    androidDeviceidPercent () {
      if (!this.totalDeviceid) {
        return '0.00'
      }
      const {
        android_total_deviceid: androidDeviceid
      } = this.totalData
      return (androidDeviceid * 100 / this.totalDeviceid).toFixed(2)
    },

    androidChartData () {
      const value = this.androidDeviceidPercent - 0
      return {
        value,
        color: 'rgba(71, 189, 255, 1)',
        endColor: 'rgba(44, 104, 255, 1)'
      }
    }
  },
  mounted () {
  },
  methods: {
    ...mapActions([
      'showMealBuyDialog'
    ]),

    goToUpgrade () {
      this.showMealBuyDialog()
    }
  }
}
