/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 17:19:26
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-16 17:21:41
 */
import IconTitle from './iconTitle.vue'
export default IconTitle
