/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 15:46:11
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-16 15:55:20
 */
import TotalData from './totalData.vue'
export default TotalData
