/*
 * @Descripttion: *
 * @version: *
 * @Author: Arvin
 * @Date: 2020-11-16 17:21:03
 * @LastEditors: Arvin
 * @LastEditTime: 2020-11-16 17:24:25
 */
export default {
  name: 'IconTitle',
  props: {
    iconName: {
      type: String,
      default: 'ic_topnav_user'
    },
    title: {
      type: String,
      default: '标题'
    },
    titleColor: {
      type: String,
      default: '#9D9FA2'
    }
  }
}
